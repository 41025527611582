$prefix: 'vqj-';

$color_green: #00cb00;
$color_darkgreen: #008800;
$color_darkgray: #222222;
$color_white: #fafafa;
$color_beige: #f0f0ee;


// import some useful utilities and variables from Bootstrap
// Inspired by https://www.amadousall.com/the-good-parts-of-bootstrap-4-you-are-missing-in-your-angular-material-projects/

// overrides some variables before importing Bootstrap files
// note : bootstrap uses !default keyword that prevents sass from assigning the value if variable is already defined
$link-color: #3f51b5;
$link-hover-color: currentColor;
$link-hover-decoration: none;
$label-margin-bottom: 0;

// Imports functions, variables, and mixins that are needed by other Bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

